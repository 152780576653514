import {useEffect} from "react";


export function Chiama() {

    useEffect(() => {
        window.location.replace("tel:+393347287621")
    }, []);

    return <div/>
}