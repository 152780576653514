import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {Prenota} from "../pages/Prenota";
import {Booking} from "../pages/Booking";
import {GestisciPrenotazione} from "../pages/GestisciPrenotazione";
import {Chiama} from "../pages/Chiama";

export function Router() {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/chiama" element={<Chiama />} />
                    <Route exact path="/prenota" element={<Prenota />} />
                    <Route exact path="/gestisci-prenotazione" element={<GestisciPrenotazione />} />
                    <Route exact path="/b" element={<Navigate to="/" />} />
                    <Route path="/b/:id" element={<Booking />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}